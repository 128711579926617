<template>
  <v-container class="pt-7 pb-11">
    <v-progress-linear v-if="getLoading" height="2" indeterminate/>
    <template v-else>
      <v-row class="mt-0 mb-4">
        <v-col>
          <v-breadcrumbs :items="breadcrumbsItems" divider="/" class="pa-0"/>
        </v-col>
      </v-row>
      <v-row class="mb-8" justify="space-between" align="center">
        <v-col cols="auto" class="text-left">
          <h1 class="text-h4">{{ pageHeader }} <span class="font-weight-regular text-lowercase">{{ 'relevancy'|localize }}</span></h1>
        </v-col>
      </v-row>
      <div class="white elevation-2 rounded">
        <v-container class="px-4">
          <v-form ref="userRelevancy" lazy-validation>
            <v-row class="mb-2">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'transportMode'|localize }}</h6>
                <v-select
                  :background-color="transport_mode ? 'serenade' : ''"
                  v-model="transport_mode"
                  :items="transportModeList"
                  item-text="value"
                  item-value="key"
                  dense
                  clearable
                  outlined
                  :disabled="saveLoading"
                  :rules="[validationRules.required]"
                >
                  <template v-slot:item="{ item, on }">
                    <v-icon class="mr-2" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                    <v-list-item-title>{{ item['value']|localize }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <div class="d-flex align-center">
                      <v-icon class="mr-1" color="silver-chalice">{{ TRANSPORT_ICONS[item['value']] }}</v-icon>
                      <v-list-item-title class="mr-2">{{ item['value']|localize }}</v-list-item-title>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'typeOfGoods'|localize }}</h6>
                <v-select
                  :background-color="type_of_goods.length ? 'serenade' : ''"
                  v-model="type_of_goods"
                  :items="goodTypesList"
                  item-text="value"
                  item-value="key"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  :rules="[validationRules.requiredArray]"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <span v-if="index > 0" class="mr-1">, </span>
                      {{ item.value }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="6">
                <div class="d-flex align-center mb-2">
                  <h6 class="text-subtitle-1">{{ 'fromToPresets'|localize }} <span style="font-weight: normal">({{ 'optional'|localize }})</span></h6>
                  <v-tooltip top color="black" content-class="px-2">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" size="20" color='silver-chalice' class="ml-2">mdi-information
                      </v-icon>
                    </template>
                    <template>
                      <div class="py-1">
                        <ul class="px-0" style="list-style: none">
                          <li><strong>{{ 'domestic'|localize }}</strong> - {{ 'domesticNote'|localize }}</li>
                          <li><strong>{{ 'cross'|localize }}</strong> - {{ 'crossNote'|localize }}</li>
                          <li><strong>{{ 'import'|localize }}</strong> - {{ 'importNote'|localize }}</li>
                          <li><strong>{{ 'importEurope'|localize }}</strong> - {{ 'importEuropeNote'|localize }}</li>
                          <li><strong>{{ 'export'|localize }}</strong> - {{ 'exportNote'|localize }}</li>
                          <li><strong>{{ 'exportEurope'|localize }}</strong> - {{ 'exportEuropeNote'|localize }}</li>
                          <li><strong>{{ 'all'|localize }}</strong> - {{ 'anyNote'|localize }}</li>
                        </ul>
                      </div>
                    </template>
                  </v-tooltip>
                </div>
                <v-select
                  :background-color="selectedPreset ? 'serenade' : ''"
                  v-model="selectedPreset"
                  :items="countryPresets"
                  item-text="name"
                  item-value="key"
                  dense
                  clearable
                  outlined
                  :disabled="saveLoading"
                  @change="presetHandler"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title><b>{{ item.name }}</b> ({{ item.description }})</v-list-item-title>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'deliveryTerms'|localize }}</h6>
                <v-select
                  :background-color="delivery_terms.length ? 'serenade' : ''"
                  v-model="delivery_terms"
                  :items="deliveryTerms"
                  item-text="text"
                  item-value="value"
                  dense
                  clearable
                  outlined
                  multiple
                  :placeholder="'selectTerms' | localize"
                  :rules="[validationRules.requiredArray]"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <span v-if="index > 0" class="mr-1">, </span>
                      {{item.value}}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'regionFrom'|localize }} <span style="font-weight: normal">({{ 'optional'|localize }})</span></h6>
                <v-select
                  :background-color="region_from.length ? 'serenade' : ''"
                  v-model="region_from"
                  :items="REGIONS"
                  :item-text="item => `${item.value}`"
                  :item-value="item => item.key"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  @change="regionHandler('region_from')"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <span v-if="index > 0" class="mr-1">, </span>
                      {{ item.value }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'countryFrom'|localize }}</h6>
                <v-autocomplete
                  :background-color="country_from.length ? 'serenade' : ''"
                  v-model="country_from"
                  :items="COUNTRY_LIST"
                  :item-text="item => `${item.name} ${item.code}`"
                  :item-value="item => `${item.code}`"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  :rules="[validationRules.requiredArray]"
                  @change="clearFromPresets"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.name }} ({{ item.code }})</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index <= 3">
                      <span v-if="index > 0">, </span>
                      {{ item.name }}
                    </span>
                    <span v-if="index === country_from.length - 1" class="mr-1"/>
                    <span v-if="index === 4" class="grey--text text-caption mx-1">+{{ country_from.length - 4 }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'regionTo'|localize }} <span style="font-weight: normal">({{ 'optional'|localize }})</span></h6>
                <v-select
                  :background-color="region_to.length ? 'serenade' : ''"
                  v-model="region_to"
                  :items="REGIONS"
                  :item-text="item => `${item.value}`"
                  :item-value="item => item.key"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  @change="regionHandler('region_to')"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <span v-if="index > 0" class="mr-1">, </span>
                      {{ item.value }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <div class='switch-button-holder'>
                  <v-btn icon color="primary" class='switch-button' @click='switchCountryValues'>
                    <v-icon>mdi-swap-vertical</v-icon>
                  </v-btn>
                </div>
                <h6 class="text-subtitle-1 mb-2">{{ 'countryTo'|localize }}</h6>
                <v-autocomplete
                  :background-color="country_to.length ? 'serenade' : ''"
                  v-model="country_to"
                  :items="COUNTRY_LIST"
                  :item-text="item => `${item.name} ${item.code}`"
                  :item-value="item => `${item.code}`"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  :rules="[validationRules.requiredArray]"
                  @change="clearToPresets"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.name }} ({{ item.code }})</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index <= 3">
                      <span v-if="index > 0">, </span>
                      {{ item.name }}
                    </span>
                    <span v-if="index === country_to.length - 1" class="mr-1"/>
                    <span v-if="index === 4" class="grey--text text-caption mx-1">+{{ country_to.length - 4 }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'specialRequirements'|localize }}</h6>
                <v-select
                  :background-color="special_requirements.length ? 'serenade' : ''"
                  v-model="special_requirements"
                  :items="requirementsList"
                  :item-text="item => `${item.value}`"
                  :item-value="item => `${item.key}`"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  :rules="[validationRules.requiredArray]"
                >
                  <template v-slot:item="{ item, on }">
                    <v-icon class="mr-2" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                    <v-list-item-title>{{ item['key']|localize }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <div class="d-flex align-center">
                      <v-icon class="mr-1" :color="SPECIAL_REQUIREMENTS_COLORS[item['key']]">{{ SPECIAL_REQUIREMENTS_ICONS[item['key']] }}</v-icon>
                      <v-list-item-title class="mr-2">{{ item['key']|localize }}</v-list-item-title>
                    </div>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="6">
                <h6 class="text-subtitle-1 mb-2">{{ 'receiver'|localize }}</h6>
                <v-select
                  :background-color="receiver.length ? 'serenade' : ''"
                  v-model="receiver"
                  :items="receiverList"
                  item-text="value"
                  item-value="key"
                  dense
                  clearable
                  outlined
                  multiple
                  :disabled="saveLoading"
                  :rules="[validationRules.requiredArray]"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title>{{ item.value }}</v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span>
                      <span v-if="index > 0">, </span>
                      {{ item.value }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
            <v-row class="mb-2">
              <v-col cols="6" class="ml-auto">
                <h6 class="text-subtitle-1 mb-2">{{ 'blockedClients'|localize }}</h6>
                <v-autocomplete
                  :background-color="blocked_clients.length ? 'serenade' : ''"
                  v-model="blocked_clients"
                  :items="clients"
                  :item-text="item => `${item.name} ${item.id} ${item.email}`"
                  item-value="user_id"
                  dense
                  clearable
                  outlined
                  multiple
                  :loading="clientsLoading"
                  :disabled="saveLoading || clientsLoading"
                >
                  <template v-slot:item="{ item, on }">
                    <v-list-item-title><strong>{{ item.name }}</strong> <i>(ID:{{ item.id }}, {{ item.email }})</i></v-list-item-title>
                  </template>
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index <= 1">
                      <span v-if="index > 0">, </span>
                      {{ item.name }}
                    </span>
                    <span v-if="index === blocked_clients.length - 1" class="mr-1"/>
                    <span v-if="index === 2" class="grey--text text-caption mx-1">+{{ blocked_clients.length - 2 }}</span>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6">
                <v-btn
                  v-if="relevancy_id"
                  rounded
                  depressed
                  color="error"
                  :loading="deleteLoading"
                  :disabled="saveLoading || deleteLoading"
                  @click="$refs.deleteRelevancyDialog.open()"
                >
                  {{ 'delete'|localize }}
                </v-btn>
              </v-col>
              <v-col cols="6" class="d-flex justify-end">
                <v-btn
                  rounded
                  depressed
                  color="primary"
                  :loading="saveLoading"
                  :disabled="saveLoading || deleteLoading"
                  @click="openConfirmDialog"
                > {{ 'save'|localize }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-container>
      </div>
    </template>
    <SimpleDialog
      ref="relevancyDialog"
      :title="'saveUserRelevancy'|localize"
      :text="'saveUserRelevancyText'|localize"
      :accept-text="'save'|localize"
      :decline-text="'back'|localize"
      @decline="$refs.relevancyDialog.close()"
      @accept="saveRelevancy"
    />
    <SimpleDialog
      ref="deleteRelevancyDialog"
      :title="'deleteRelevancy'|localize"
      :text="'deleteRelevancyText'|localize"
      :accept-text="'delete'|localize"
      :decline-text="'back'|localize"
      @decline="$refs.deleteRelevancyDialog.close()"
      @accept="deleteRelevancy"
    />
  </v-container>
</template>

<script>
  import validationRules from '@/helpers/validationRules';
  import localize from '@/filters/localize';
  import api from '@/api/api';
  import SimpleDialog from '@/components/common/SimpleDialog.vue';
  import methods from '@/helpers/methods';
  import { TRANSPORT_ICONS } from '@/enums/transportMode';
  import { REGIONS } from '@/enums/regions';
  import { COUNTRY_LIST } from '@/enums/countryList';
  import { PRESETS } from '@/enums/presets';
  import { SPECIAL_REQUIREMENTS_COLORS, SPECIAL_REQUIREMENTS_ICONS } from '@/enums/specialRequirements';

  export default {
    name: 'UserRelevancy',
    components: {SimpleDialog},

    data() {
      return {
        TRANSPORT_ICONS,
        REGIONS,
        COUNTRY_LIST,
        validationRules: validationRules,
        company_id: this.$router.currentRoute.query.company_id,
        user_id: this.$router.currentRoute.query.user_id,
        relevancy_id: this.$router.currentRoute.query.relevancy_id,
        copy_id: this.$router.currentRoute.query.copy_id,
        name: '',
        transport_mode: "",
        type_of_goods: [],
        selectedPreset: '',
        delivery_terms: [],
        region_from: [],
        region_to: [],
        country_from: [],
        country_to: [],
        special_requirements: [],
        receiver: [],
        getLoading: false,
        clientsLoading: false,
        clients: [],
        blocked_clients: [],
        deleteLoading: false,
        saveLoading: false,
      };
    },

    async mounted() {
      if (this.user_id) {
        try {
          this.getLoading = true;
          let userData = await api.getSingleCompanyUser(this.user_id);
          if (this.relevancy_id) {
            let relevancyData = await api.getSingleUserRelevancy(this.relevancy_id);
            this.setRelevancy(relevancyData.data);
          }
          if (this.copy_id) {
            let relevancyData = await api.getSingleUserRelevancy(this.copy_id);
            this.setRelevancy(relevancyData.data);
          }
          if (!this.relevancy_id && !this.copy_id) {
            let relevancyData = await api.getCompanyRelevancyAdmin(this.company_id);
            this.setRelevancyFromCompany(relevancyData.data);
          }
          this.name = userData.data.name;
          this.getLoading = false;
        } catch (e) {
          this.getLoading = false;
        }
      }
      this.getClients();
    },

    computed: {
      SPECIAL_REQUIREMENTS_ICONS() {
        return SPECIAL_REQUIREMENTS_ICONS
      },
      SPECIAL_REQUIREMENTS_COLORS() {
        return SPECIAL_REQUIREMENTS_COLORS
      },
      transportModeList() {
        return this.$store.getters.transportModeList;
      },
      goodTypesList() {
        return this.$store.getters.goodTypesSorted.all;
      },
      requirementsList() {
        return this.$store.getters.requirementsList;
      },
      receiverList() {
        return this.$store.getters.receiverList;
      },
      deliveryTerms() {
        return this.$store.getters.deliveryTerms;
      },
      countryPresets() {
        return this.$store.getters.countryPresets;
      },
      pageHeader() {
        return (this.user_id) ? this.name : localize('createUser');
      },
      breadcrumbsItems() {
        return [
          {
            text: localize('companies'),
            disabled: false,
            to: {name: 'admin-companies'},
          },
          {
            text: localize('editCompany'),
            disabled: false,
            to: {name: 'create-company', query: {company_id: this.company_id}, hash: '#users'},
          },
          {
            text: localize('editUser'),
            disabled: false,
            to: {name: 'create-user', query: {company_id: this.company_id, user_id: this.user_id}, hash: '#relevancy'},
          },
          {
            text: localize('userRelevancy'),
            disabled: true,
          },
        ];
      }
    },

    methods: {
      presetHandler() {
        this.region_to = [];
        this.region_from = [];
        switch (this.selectedPreset) {
          case PRESETS.DOMESTIC:
            this.country_from = ['SE'];
            this.country_to = ['SE'];
            break;
          case PRESETS.CROSS:
            this.country_from = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            this.country_to = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            break;
          case PRESETS.IMPORT:
            this.country_from = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            this.country_to = ['SE'];
            break;
          case PRESETS.IMPORT_EUROPE:
            this.country_from = this.COUNTRY_LIST.filter(item => item.region_code === 150 && item.code !== 'SE').map(item => item.code);
            this.country_to = ['SE'];
            break;
          case PRESETS.EXPORT:
            this.country_from = ['SE'];
            this.country_to = this.COUNTRY_LIST.filter(item => item.code !== 'SE').map(item => item.code);
            break;
          case PRESETS.EXPORT_EUROPE:
            this.country_from = ['SE'];
            this.country_to = this.COUNTRY_LIST.filter(item => item.region_code === 150 && item.code !== 'SE').map(item => item.code);
            break;
          case PRESETS.ALL:
            this.country_from = this.COUNTRY_LIST.map(item => item.code);
            this.country_to = this.COUNTRY_LIST.map(item => item.code);
            break;
          default:
            this.country_from = [];
            this.country_to = [];
        }
      },
      clearFromPresets() {
        this.region_from = [];
        this.selectedPreset = '';
      },
      clearToPresets() {
        this.region_to = [];
        this.selectedPreset = '';
      },
      regionHandler(region) {
        this.selectedPreset = '';
        let localCountries = (this[region].length) ? [...this.filter_countries_by_region(region).map(item => item.code)] : [];
        if (region === 'region_from') {
          this.country_from = localCountries;
        } else {
          this.country_to = localCountries;
        }
      },
      filter_countries_by_region(region) {
        let localCountries = [];
        this[region].forEach(element => {
          localCountries = localCountries.concat(this.COUNTRY_LIST.filter(item => item.region_code === element));
        });
        return (localCountries.length) ? localCountries : [];
      },
      openConfirmDialog() {
        if (!this.$refs.userRelevancy.validate()) return;
        this.$refs.relevancyDialog.open();
      },
      setRelevancyFromCompany(relevancyData) {
        relevancyData.type_of_goods && (this.type_of_goods = relevancyData.type_of_goods);
        relevancyData.delivery_terms && (this.delivery_terms = relevancyData.delivery_terms);
        relevancyData.country_from && (this.country_from = relevancyData.country_from.map(item => item.code));
        relevancyData.country_to && (this.country_to = relevancyData.country_to.map(item => item.code));
        relevancyData.special_requirements && (this.special_requirements = relevancyData.special_requirements);
        relevancyData.receiver && (this.receiver = relevancyData.receiver);
        relevancyData.blocked_clients && (this.blocked_clients = relevancyData.blocked_clients);
      },
      setRelevancy(relevancyData) {
        relevancyData.transport_mode && (this.transport_mode = relevancyData.transport_mode);
        relevancyData.type_of_goods && (this.type_of_goods = relevancyData.type_of_goods);
        relevancyData.delivery_terms && (this.delivery_terms = relevancyData.delivery_terms);
        relevancyData.country_from && (this.country_from = relevancyData.country_from.map(item => item.code));
        relevancyData.country_to && (this.country_to = relevancyData.country_to.map(item => item.code));
        relevancyData.special_requirements && (this.special_requirements = relevancyData.special_requirements);
        relevancyData.receiver && (this.receiver = relevancyData.receiver);
        relevancyData.blocked_clients && (this.blocked_clients = relevancyData.blocked_clients);
      },
      queryData() {
        let queryData = {};
        queryData.company_id = this.company_id;
        queryData.user_id = this.user_id;
        queryData.transport_mode = this.transport_mode;
        queryData.delivery_terms = JSON.stringify(this.delivery_terms);
        queryData.country_from = JSON.stringify(this.country_from.map(countryCode => {
          return {code:countryCode, name: COUNTRY_LIST.filter(item => item.code === countryCode)[0].name}
        }));
        queryData.country_to = JSON.stringify(this.country_to.map(countryCode => {
          return {code:countryCode, name: COUNTRY_LIST.filter(item => item.code === countryCode)[0].name}
        }));
        queryData.type_of_goods = JSON.stringify(this.type_of_goods)
        queryData.special_requirements = JSON.stringify(this.special_requirements);
        queryData.receiver = JSON.stringify(this.receiver);
        queryData.blocked_clients = JSON.stringify(this.blocked_clients);
        return queryData;
      },
      async saveRelevancy() {
        try {
          this.saveLoading = true;
          if (this.relevancy_id) {
            await api.editUserRelevancy(this.relevancy_id, this.queryData())
          } else {
            await api.createUserRelevancy(this.queryData());
          }
          this.saveLoading = false;
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'create-user', query: {company_id: this.company_id, user_id: this.user_id}, hash: '#relevancy'});
        } catch (e) {
          this.saveLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      async deleteRelevancy() {
        try {
          this.deleteLoading = true;
          await api.deleteUserRelevancy(this.relevancy_id, this.company_id, this.user_id);
          this.deleteLoading = false;
          methods.showGlobalSnackbar('success', localize('done'));
          this.$router.push({name: 'create-user', query: {company_id: this.company_id, user_id: this.user_id}, hash: '#relevancy'});
        } catch (e) {
          this.deleteLoading = false;
          methods.showGlobalSnackbar('error', methods.getErrors(e.response));
        }
      },
      async getClients () {
        try {
          this.clientsLoading = true;
          let clientsData = await api.getClients({ paginate: 0 });
          this.clients = clientsData.data.data;
        } finally {
          this.clientsLoading = false;
        }
      },
      switchCountryValues () {
        let localCountryFrom = this.country_from;
        let localCountryTo = this.country_to;
        this.country_from = localCountryTo;
        this.country_to = localCountryFrom;
        this.clearFromPresets();
        this.clearToPresets();
      }
    },
  };
</script>

<style lang="scss" scoped>
  .switch-button-holder {
    position: relative;
  }
  .switch-button {
    position: absolute;
    top: -45px;
    left: 48%;
  }
</style>
