<template>
  <v-dialog v-model="dialog" width="400" overlay-color="black" :overlay-opacity="0.3" @click:outside="decline">
    <v-card class="text-center px-6">
      <v-card-title class="text-h5 d-block pt-6 pb-6 px-0" style="word-break: normal;">{{ title }}</v-card-title>
      <v-card-text class="text-body-1 pb-2 px-0">{{ text }}</v-card-text>
      <v-card-actions class="py-6 px-0">
        <v-row v-if="closeButton" justify="center">
          <v-col cols="6">
            <v-btn color="primary" block depressed rounded @click="close">{{ 'close'|localize }}</v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <v-btn color="primary" block outlined rounded @click="decline">{{ declineText }}</v-btn>
          </v-col>
          <v-col>
            <v-btn color="primary" block depressed rounded @click="accept">{{ acceptText }}</v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'SimpleDialog',
    props: ['title', 'text', 'acceptText', 'declineText', 'closeButton'],
    data() {
      return {
        dialog: false,
      };
    },
    methods: {
      open() {
        this.dialog = true;
      },
      close() {
        this.dialog = false;
      },
      accept() {
        this.dialog = false;
        this.$emit('accept');
      },
      decline() {
        this.dialog = false;
        this.$emit('decline');
      },
    },
  };
</script>
