import localize from '@/filters/localize';

export default {
  required: v => v && !!v.trim() || localize('canNotEmpty'),
  required_goods: v => v && !!v || localize('canNotEmptyGoods'),
  required_long: v => v && !!v.trim() || localize('fieldIsRequired'),
  requiredNumber: v => v && !!v || localize('canNotEmpty'),
  requiredArray: v => v.length > 0 || localize('canNotEmpty'),
  requiredObject: v => Object.keys(v).length > 0 || localize('required'),
  integer: v => {
    const pattern = /^\d+$/;
    return !v || pattern.test(v) || localize('onlyInteger')
  },
  four_digits: v => {
    return !v || v.toString().length === 4 || localize('must_4_digits')
  },
  number: v => {
    const pattern = /(?<=^| )\d+(\.\d+)?(?=$| )/;
    return !v || pattern.test(v) || localize('onlyNumbers');
  },
  maxCurrencyMillion: v => !v || v <= 1000000 || localize('priceCanNotBeMoreOneMillion'),
  email: v => {
    const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !v || pattern.test(v) || localize('invalidEmail');
  },
  required_not_zero: v => {
    return !v || Number(v) > 0 || localize('onlyInteger')
  },
  url: v => {
    const pattern = /^https:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    return !v || pattern.test(v) || localize('invalidUrl');
  }
}
